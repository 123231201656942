import clsx from 'clsx'

/* blocks */
import Link from '@/atoms/Link/Link'

/* assets */
import { ReactComponent as CheckOutlinedIcon } from 'public/assets/icons/check_outlined.svg'

interface Props {
  class?: string
  headline: string
  primaryCta: StrapiLinkSharedComponent
  secondaryCta?: StrapiLinkSharedComponent
  mode?: 'card' | 'full_width'
  variant?: 'blue' | 'dark' | 'purple'
  list?: {
    id: string | number
    item: string
  }[]
}

function FooterCta({
  headline,
  primaryCta,
  secondaryCta,
  mode = 'card',
  variant = 'blue',
  ...props
}: Props): JSX.Element {
  const getVariant = () => {
    switch (variant) {
      case 'blue':
        return 'bg-blue-web-50'
      case 'dark':
        return 'bg-blue-web-90'
      case 'purple':
        return 'bg-purple-60'
      default:
        return 'bg-blue-web-50'
    }
  }
  return (
    <section
      className={clsx(
        props.class,
        mode === 'card' ? 'my-12 mx-6 md:mx-18 ' : getVariant(),
        'font-heading text-white | text-center | font-medium'
      )}
    >
      <div
        className={clsx(
          mode === 'card' ? `${getVariant()} | rounded-2xl` : null,
          'container | py-10 md:py-18'
        )}
      >
        {headline ? (
          <h2
            className={clsx(
              mode === 'card' ? 'max-w-col-8' : 'max-w-col-10',
              'text-3xl md:text-5xl font-medium tracking-tight | mx-auto mb-10'
            )}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        ) : null}
        <div className="font-body font-medium flex flex-col sm:flex-row justify-center | gap-4">
          <Link href={primaryCta.href} className="new-btn-tertiary">
            {primaryCta.title}
          </Link>
          {secondaryCta ? (
            <Link href={secondaryCta.href} className="new-btn-secondary">
              {secondaryCta.title}
            </Link>
          ) : null}
        </div>
        {props.list ? (
          <ul className="flex flex-wrap md:items-center md:justify-center | gap-5 md:gap-10 | pt-11">
            {props.list.map(item => (
              <li
                key={item.id}
                className="font-body text-xl text-left  |  flex items-center  |  mb-4"
              >
                <CheckOutlinedIcon className="text-yellow-50 flex-shrink-0  |  mr-4" />
                {item.item}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </section>
  )
}

export default FooterCta
