import type { GetStaticProps } from 'next'

import Layout from '@/cmp/templates/Layout/Layout'
import PeppertypeAITemplate from '@/cmp/templates/PeppertypeAI/PeppertypeAI'

/* utils */
import {
  INSTANT_REVALIDATION,
  TEN_MINS_REVALIDATION
} from '@/utils/constants/revalidation'
import { getStrapiGraphqlData } from '@/utils/api/wrapper'
import {
  PeppertypeAIPageAttributes,
  peppertypeAIPageQuery,
  PeppertypeAIPageResponse
} from '@/utils/queries/peppertype-ai'
import { capture404PageError } from '@/utils/functions/elk-server'

interface Props {
  data: PeppertypeAIPageAttributes
}

function PeppertypeAI({ data }: Props): JSX.Element {
  return (
    <Layout title={data.seo.title} canonical="/peppertype-ai/">
      <PeppertypeAITemplate data={data} />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const result = await getStrapiGraphqlData<PeppertypeAIPageResponse>(
    peppertypeAIPageQuery.loc?.source.body
  )

  const page = result?.ptHomepage.data

  if (!page) {
    capture404PageError(`/peppertype-ai/`)
    return { notFound: true, revalidate: INSTANT_REVALIDATION }
  }

  return {
    props: {
      data: page.attributes
    },
    revalidate: TEN_MINS_REVALIDATION
  }
}

export default PeppertypeAI
