import clsx from 'clsx'
import { useState, useEffect, useId } from 'react'
import Image from 'next/image'

/* blocks */
import Link from '@/atoms/Link/Link'

/* assets */
import { ReactComponent as ChevronRight } from 'public/assets/icons/chevron-right.svg'

interface ScrollFeatureDeskProps {
  class?: string
  title?: string
  description?: string
  iconTagDetails?: {
    icon?: StrapiImageFile
    tag?: string
    color?: string
  }
  mode?: 'dark' | 'light'
  list: {
    id: string | number
    icon?: StrapiImageFile
    tag?: string | null
    title: string
    description: string
    image?: StrapiImageFile
    videoUrl?: string
    link?: StrapiLinkSharedComponent | null
    bgColor: string | null
    tagColor?: string | null
  }[]
  layout?: 'content-left' | 'content-right'
}

interface ScrollFeatureProps {
  mode?: 'dark' | 'light'
  feature: {
    title: string
    description: string
    image?: StrapiImageFile
    icon?: StrapiImageFile
    tag?: string
    videoUrl?: string
    link?: StrapiLinkSharedComponent | null
    bgColor: string | null
  }
}

function ScrollFeatures({
  layout = 'content-right',
  ...props
}: ScrollFeatureDeskProps): JSX.Element {
  const [activeFeature, setActiveFeature] = useState(0)
  const componentId = useId().replace(/:/g, '')

  const image =
    props.list[Number(activeFeature)] && props.list[Number(activeFeature)].image
  const bgColor = props.list[Number(activeFeature)]?.bgColor ?? ''

  useEffect(() => {
    // video-section observer
    const observer = new IntersectionObserver(
      entries => {
        const id = entries[0].target.getAttribute('data-id')

        if (entries[0].isIntersecting && id) {
          setActiveFeature(parseInt(id))
          videoElement?.load()
        }
      },
      { threshold: 1 }
    )

    const elements = document.querySelectorAll<HTMLElement>(
      `#${componentId} .element`
    )
    const videoElement = document.querySelector<HTMLVideoElement>(
      `#${componentId} .feature-video`
    )
    elements.forEach(element => {
      observer.observe(element)
    })
  }, [componentId])

  return (
    <section
      id={componentId}
      className={clsx(
        props.class,
        props.mode === 'dark' ? 'bg-blue-web-90 text-white' : null,
        'py-10 md:py-18'
      )}
    >
      <div className="container | pb-10 md:pb-18">
        <header className="max-w-col-9 | md:mr-10">
          {props.iconTagDetails ? (
            <div className="flex items-center | pb-9">
              {props.iconTagDetails.icon?.url ? (
                <Image
                  src={props.iconTagDetails.icon.url}
                  alt={props.iconTagDetails.icon.alternativeText ?? ''}
                  width={props.iconTagDetails.icon.width}
                  height={props.iconTagDetails.icon.height}
                />
              ) : null}
              <p
                className="uppercase text-xl font-semibold | ml-3"
                style={{ color: props.iconTagDetails.color }}
              >
                {props.iconTagDetails.tag}
              </p>
            </div>
          ) : null}
          <h2
            className={clsx(
              props.mode === 'dark' ? 'text-white' : 'text-gray-90',
              props.description ? 'mb-5' : '',
              'font-heading text-3xl lg:text-5xl font-medium | max-w-col-10'
            )}
            dangerouslySetInnerHTML={{ __html: props.title ?? '' }}
          ></h2>
          {props.description ? (
            <div
              className={clsx(
                props.mode === 'dark' ? 'text-white' : 'text-gray-60',
                'font-body font-normal text-xl description'
              )}
              dangerouslySetInnerHTML={{ __html: props.description }}
            ></div>
          ) : null}
        </header>
      </div>

      <div className="md:container">
        <div className="hidden md:block">
          <div className="grid grid-cols-12 gap-8">
            <div
              className={clsx(
                layout === 'content-right' ? 'col-start-8 order-2' : '',
                'col-span-5 items-center font-heading'
              )}
            >
              {props.list?.map((feature, index) => {
                return (
                  <div
                    key={'featuresList-' + index}
                    className="flex flex-col justify-center element | min-h-[612px] py-10"
                    data-id={index}
                  >
                    {feature.tag ? (
                      <div className="flex items-center mb-6">
                        <p
                          className="text-small tracking-widest uppercase font-semibold"
                          style={{ color: feature.tagColor ?? '' }}
                        >
                          {feature.tag}
                        </p>
                      </div>
                    ) : null}
                    <h3
                      className={clsx(
                        props.mode === 'dark' ? 'text-white' : 'text-gray-90',
                        'text-3xl lg:text-4xl font-medium | lg:mb-8'
                      )}
                      dangerouslySetInnerHTML={{ __html: feature.title }}
                    />
                    <div
                      className={clsx(
                        props.mode === 'dark' ? 'text-white' : 'text-gray-60',
                        'font-body font-normal text-xl | lg:mb-9 md:pr-10 description'
                      )}
                      dangerouslySetInnerHTML={{ __html: feature.description }}
                    />
                    {feature.link ? (
                      <div
                        className={clsx(
                          props.mode === 'dark'
                            ? 'text-white'
                            : 'text-blue-web-50',
                          'text-base font-body font-medium '
                        )}
                      >
                        <Link
                          href={feature.link.href}
                          target={feature.link.target}
                          className="flex items-center underline underline-offset-8"
                        >
                          {feature.link.title}
                          <ChevronRight className="ml-4" />
                        </Link>
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </div>

            <div
              className={clsx(
                layout === 'content-right' ? '' : 'col-start-7',
                'col-span-6 | relative'
              )}
            >
              <div
                className="sticky flex items-center top-[calc(50%-260px)] rounded-2xl"
                style={{ background: bgColor }}
              >
                {image ? (
                  <Image
                    src={image.url}
                    alt={image.alternativeText ?? ''}
                    width={image.width}
                    height={image.height}
                    className="max-w-[540px] md:px-4 xl:px-0 mx-auto"
                    priority
                  />
                ) : (
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="feature-video | px-4 mx-auto"
                  >
                    <source
                      src={props.list[Number(activeFeature)]?.videoUrl}
                      type="video/mp4"
                    />
                    <source
                      src={props.list[Number(activeFeature)]?.videoUrl}
                      type="video/webm"
                    />
                  </video>
                )}
              </div>
            </div>
          </div>
        </div>

        {props.list?.map((feature, index) => {
          return (
            <Feature
              key={'featureList-' + index}
              mode={props.mode}
              feature={{
                title: feature.title,
                description: feature.description,
                image: feature.image,
                tag: feature.tag as string,
                icon: feature.icon,
                videoUrl: feature.videoUrl,
                link: feature.link,
                bgColor: feature.bgColor
              }}
            />
          )
        })}
      </div>
    </section>
  )
}

function Feature({ feature, mode }: ScrollFeatureProps): JSX.Element {
  return (
    <div className="font-heading grid | gap-x-8 gap-y-8 | md:hidden">
      <div className="order-2 px-5">
        {feature.tag ? (
          <div className="flex items-center mb-6">
            <span
              className="w-8 h-8 rounded-md"
              style={{ background: feature.bgColor ?? '' }}
            />
            <p className="uppercase ml-4">{feature.tag}</p>
          </div>
        ) : null}
        <div className="flex flex-row | items-center | mb-6">
          <h3
            className={clsx(
              mode === 'dark' ? 'text-white' : 'text-gray-90',
              'text-3xl font-medium '
            )}
            dangerouslySetInnerHTML={{ __html: feature.title }}
          />
        </div>
        <div
          className={clsx(
            mode === 'dark' ? 'text-white' : 'text-gray-60',
            'font-body text-lg  | mb-7 md:pr-10 '
          )}
          dangerouslySetInnerHTML={{ __html: feature.description }}
        />
        {feature.link && (
          <div
            className={clsx(
              mode === 'dark' ? 'text-white' : 'text-blue-web-50',
              'font-body text-base font-medium mb-8'
            )}
          >
            <Link
              href={feature.link.href}
              target={feature.link.target}
              className="flex items-center underline underline-offset-8"
            >
              {feature.link.title}
              <ChevronRight className="ml-4" />
            </Link>
          </div>
        )}
      </div>
      <div className="order-1" style={{ background: feature.bgColor ?? '' }}>
        {feature.image ? (
          <Image
            src={feature.image.url}
            alt={feature.image.alternativeText ?? ''}
            width={feature.image.width}
            height={feature.image.width}
            className="px-8 mx-auto"
            priority
          />
        ) : (
          <video
            width="750"
            height="500"
            autoPlay
            muted
            loop
            playsInline
            className="feature-video | px-8 mx-auto"
          >
            <source src={feature.videoUrl} type="video/mp4" />
            <source src={feature.videoUrl} type="video/webm" />
          </video>
        )}
      </div>
    </div>
  )
}

export default ScrollFeatures
