import clsx from 'clsx'
import { useState } from 'react'

/* blocks */
import Accordion from '@/atoms/Accordion/Accordion'

/* assets */
import { ReactComponent as PlusIcon } from 'public/assets/icons/faq/faqPlusNew.svg'

/* styles */
import styles from './_.module.css'

interface FaqProps {
  title: string
  list: {
    id: string | number
    question: string
    answer: string
  }[]
}

function FAQ({ title, list }: FaqProps): JSX.Element {
  const [activeKey, setActiveKey] = useState<number | string | undefined>(
    list[0]?.id
  )
  return (
    <section>
      <div className="container | font-heading | py-16 md:py-18">
        <h2 className="text-3xl md:text-5xl font-semibold | mb-10">{title}</h2>
        {list.map((value, index) => (
          <div
            key={value.id}
            className={clsx(
              'py-7',
              index !== list.length - 1 ? 'border-b-2 border-b-gray-10' : ''
            )}
          >
            <div className="flex gap-x-3 lg:gap-x-14">
              <span
                className={clsx(
                  'text-base lg:text-2xl font-semibold text-blue-90'
                )}
              >
                {(index + 1).toString() + '.'}
              </span>
              <Accordion
                elementKey={value.id}
                activeKey={activeKey}
                defaultOpen={value.id === list[0].id}
                title={value.question}
                handleChange={(key, isOpen) => {
                  isOpen ? setActiveKey(key) : setActiveKey('')
                }}
                className={clsx(
                  styles.accordion,
                  'font-heading text-xl lg:text-2xl font-semibold w-full'
                )}
                titleClass="max-w-[250px] md:max-w-col-10"
                Icon={PlusIcon}
                IconClass={activeKey === value.id ? styles.closeIconClass : ''}
              >
                <div
                  className="font-body text-base font-normal lg:text-xl | pt-4 max-w-col-8"
                  dangerouslySetInnerHTML={{ __html: value.answer ?? '' }}
                />
              </Accordion>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default FAQ
