import clsx from 'clsx'

/* blocks */
import PageHero from '@/cmp/organism/PageHero/PageHero'
import ScrollFeatures from '@/cmp/organism/Feature/ScrollFeatures'
import FooterCta from '@/cmp/organism/FooterCta/FooterCta'
import FAQNew from '@/cmp/organism/FAQNew/FAQNew'

import { PeppertypeAIPageAttributes } from '@/utils/queries/peppertype-ai'

/* styles */
import styles from './_.module.css'

interface Props {
  data: PeppertypeAIPageAttributes
}

function PeppertypeAI({ data }: Props): JSX.Element {
  return (
    <>
      <PageHero
        class={clsx(styles.peppertypeAI_page_hero, 'mb-5 lg:mb-10')}
        primaryHeadline={data.hero.title}
        subText={data.hero.description}
        primaryCta={data.hero.primaryCta}
        secondaryCta={data.hero.secondaryCta}
        footNote={data.hero.footnote}
        image={data.hero.image?.data?.attributes}
        layout="left"
        mode="light"
      />

      {data.hero.videoLink ? (
        <div className="container pb-20 lg:pb-48">
          <iframe
            src={data.hero.videoLink}
            title="hero_video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="block | w-full | aspect-video | mx-auto rounded-2xl"
          />
        </div>
      ) : null}

      {data.featureList.length > 0 ? (
        <ScrollFeatures
          title={data.featureTitle}
          list={data.featureList.map(i => ({
            id: i.id,
            link: i.link,
            bgColor: i.color,
            title: i.title ?? '',
            description: i.description ?? '',
            image: i.multimedia?.media?.data?.attributes
          }))}
          layout="content-left"
          mode="dark"
        />
      ) : null}

      {data.faqs.length > 0 ? (
        <FAQNew title="Frequently asked questions" list={data.faqs} />
      ) : null}

      {data.footerCTA.title ? (
        <FooterCta
          headline={data.footerCTA.title}
          primaryCta={data.footerCTA.primaryCta}
          variant="purple"
        />
      ) : null}
    </>
  )
}

export default PeppertypeAI
